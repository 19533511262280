<template>
  <div class="home" v-if="!showDrawer">
    <p class="message">
      <span>Your</span>
      <br />
      <!-- <span class="parentheses">(</span> -->
      {{ randomText }}
      <!-- <span class="parentheses">)</span> -->
      <br />
      <span><strong>bloom</strong> with us.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      texts: ["Dreams", "Lives", "Loves", "Works", "Days", "Talents", "Wishes"],
      randomText: "",
      timer: null,
    };
  },
  mounted() {
    this.changeText();
    setTimeout(() => {
      clearInterval(this.timer);
    }, 1000); // 1秒後にテキストの更新を停止
  },
  computed: {
    showDrawer() {
      return this.$store.state.showDrawer;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    changeText() {
      this.timer = setInterval(() => {
        let randomIndex = Math.floor(Math.random() * this.texts.length);
        this.randomText = this.texts[randomIndex];
      }, 30); // 30msごとにテキストを変更
    },
  },
};
</script>

<style scoped>
.message {
  padding: 12px;
  margin: 120px 0 120px 0;
  font-family: "Noto Sans", sans-serif;
  /* font-family: "Symphony Regular", sans-serif; */
  /* font-family: "noe-r-italic", sans-serif; */
  font-size: 10rem;
  text-align: left;
  line-height: 0.91;
  font-weight: bold;
  background: linear-gradient(
    121deg,
    #d400a5 24%,
    #7040b9 35%,
    #2b6ec7 41%,
    #35ffee 60%
  );
  -webkit-background-clip: text; /* 背景をテキストにクリップ (Webkitブラウザ対応) */
  background-clip: text; /* 背景をテキストにクリップ */
  color: transparent; /* テキストの色を透明に設定 */
}

.parentheses {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 750px) {
  .message {
    padding: 8px;
    margin: 80px 0 60px 0;
    font-size: 5rem;
  }
}
</style>
