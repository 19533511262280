import { createStore } from "vuex";

export default createStore({
  state: {
    showDrawer: false,
  },
  mutations: {
    setShowDrawer(state, payload) {
      state.showDrawer = payload;
    },
  },
});
