import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/works",
    name: "works",
    component: () =>
      import(/* webpackChunkName: "works" */ "../views/WorksView.vue"),
    meta: { title: "Works", desc: "Works of Bloom Studio." },
  },
  {
    path: "/works/:id",
    name: "work",
    component: () =>
      import(/* webpackChunkName: "works" */ "../views/WorkView.vue"),
    meta: { title: "Works", desc: "Works of Bloom Studio." },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { title: "About", desc: "About Bloom Studio." },
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/NewsView.vue"),
    meta: { title: "News", desc: "News of Bloom Studio." },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    meta: { title: "Contact", desc: "Contact Bloom Studio." },
  },
  {
    path: "/contact/thanks",
    name: "contact-thanks",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/ContactThanksView.vue"
      ),
    meta: { title: "Contact", desc: "Contact Bloom Studio." },
  },
  {
    path: "/privacy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy" */ "../views/PrivacyPolicyView.vue"
      ),
    meta: { title: "Privacy Policy", desc: "Privacy policy of Bloom Studio." },
  },
  {
    path: "/kaoru",
    name: "kaoru",
    component: () =>
      import(/* webpackChunkName: "kaoru" */ "../views/kaoru/KaoruView.vue"),
    meta: {
      title: "LINEで話せるAI - 花咲薫",
      desc: "花咲薫はChatGPTを搭載したAIとLINEでいつでもチャットができるサービスです。",
    },
  },
  {
    path: "/kaoru/terms",
    name: "kaoru-terms",
    component: () =>
      import(/* webpackChunkName: "kaoru" */ "../views/kaoru/TermsView.vue"),
    meta: {
      title: "花咲薫 - 利用規約",
      desc: "花咲薫 - 利用規約",
    },
  },
  {
    path: "/kaoru/legal-notice",
    name: "kaoru-legal-notice",
    component: () =>
      import(
        /* webpackChunkName: "kaoru" */ "../views/kaoru/LegalNoticeView.vue"
      ),
    meta: {
      title: "花咲薫 - 特定商取引法に基づく表記",
      desc: "花咲薫 - 特定商取引法に基づく表記",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
