<template>
  <header>
    <nav id="header-nav">
      <router-link to="/">
        <img
          src="../assets/logo.png"
          alt="Bloom Studio Inc. Logo"
          class="logo"
        />
      </router-link>
      <div id="burger-menu" @click="toggleDrawer">
        <i class="fa fa-bars" aria-hidden="true" v-if="!showDrawer"></i>
        <i class="fa fa-times" aria-hidden="true" v-else></i>
      </div>
      <div id="header-links" class="">
        <ul>
          <li><router-link to="/works">Works</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/news">News</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </nav>
  </header>
  <nav id="nav-links" class="c-nav" v-if="showDrawer">
    <div class="l-wrap">
      <ul class="c-nav-list">
        <li class="c-nav-item">
          <router-link to="/works" @click="toggleDrawer">Works</router-link>
        </li>
        <li class="c-nav-item">
          <router-link to="/about" @click="toggleDrawer">About</router-link>
        </li>
        <li class="c-nav-item">
          <router-link to="/news" @click="toggleDrawer">News</router-link>
        </li>
        <li class="c-nav-item">
          <router-link to="/contact" @click="toggleDrawer">Contact</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "CommonHeader",
  data() {
    return {
      isMobile: false,
      showDrawer: false,
    };
  },
  created() {
    window.addEventListener("resize", this.checkWidth);
    this.checkWidth();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      this.isMobile = window.innerWidth <= 750;
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
      this.$store.commit("setShowDrawer", !this.$store.state.showDrawer);
      this.$nextTick(() => {
        let navLinks = document.getElementById("header-nav");
        if (this.showDrawer) {
          navLinks.classList.add("show-drawer");
        } else {
          navLinks.classList.remove("show-drawer");
        }
      });
    },
  },
};
</script>

<style scoped>
header {
  background-color: transparent;
  padding: 20px 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

#header-nav {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header-nav a {
  font-weight: bold;
  color: #2c3e50;
}
#header-nav a.router-link-exact-active {
  color: #ae776b;
}

.logo {
  height: 50px;
}

#burger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
}

#burger-menu div {
  width: 2rem;
  height: 0.25rem;
  background-color: #ffffff;
}

ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

li {
  margin-right: 20px;
}

li:last-child {
  margin-right: 0;
}

.c-nav {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  /* background: #f8e9f2; */
  background: #ae776b;
  /* background: rgb(238, 238, 238); */
  color: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.l-wrap {
  padding: 0 calc(16 / 375 * 100vw);
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.c-nav .l-wrap {
  padding-top: 100px;
}

.c-nav-list {
  display: block;
  width: 100%;
  height: auto;
  -webkit-column-count: auto;
  -moz-column-count: auto;
  column-count: auto;
  margin-bottom: 80px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: left;
}

.c-nav-item + .c-nav-item {
  margin-top: 16px;
}

.c-nav a {
  font-weight: bold;
  color: #fff;
  font-size: 3.2rem;
  outline: none;
  text-decoration: none;
}
.c-nav a.router-link-exact-active {
  color: #cccccc;
}

@media screen and (max-width: 750px) {
  #burger-menu {
    display: flex;
  }
  #header-links {
    display: none;
  }
  .logo {
    height: 36px;
  }
}

.show-drawer .logo {
  opacity: 0;
}
</style>
