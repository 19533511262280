<template>
  <footer>
    <div class="footer-block">
      &copy; Bloom Studio Inc.
      <!-- <router-link to="/contact">Contact</router-link> -->
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "CommonFooter",
};
</script>

<style scoped>
footer {
  background-color: transparent;
  padding: 40px 0 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.footer-block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-block a {
  font-weight: normal;
  color: #2c3e50;
  text-decoration: none;
}
.footer-block a.router-link-exact-active {
  color: #ae776b;
}
</style>
