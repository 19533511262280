<template>
  <CommonHeader />
  <section class="content">
    <div class="l-inner">
      <router-view />
    </div>
  </section>
  <CommonFooter />
</template>

<script>
import CommonHeader from "./components/CommonHeader.vue";
import CommonFooter from "./components/CommonFooter.vue";

export default {
  name: "App",
  components: {
    CommonHeader,
    CommonFooter,
  },
  watch: {
    $route: {
      handler: function (route) {
        // title
        let title = "Bloom Studio";
        if (route.meta.title) {
          title = route.meta.title + " | Bloom Studio";
        }
        document.title = title;
        document
          .querySelector("meta[property='og:title']")
          .setAttribute("content", title);
        document
          .querySelector("meta[property='og:site_name']")
          .setAttribute("content", title);

        // description
        let desc = "株式会社Bloom Studio";
        if (route.meta.desc) {
          desc = route.meta.desc;
        }
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", desc);
        document
          .querySelector("meta[property='og:description']")
          .setAttribute("content", desc);

        // og:url
        document
          .querySelector("meta[property='og:url']")
          .setAttribute("content", "https://www.bloom-st.com" + route.path);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Symphony Regular";
  src: url("~@/assets/fonts/Symphony-Regular.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@1,500&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 98vh;
  position: relative;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.content {
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
}

.l-inner {
  padding: 0 calc(64 / 1366 * 100vw);
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.page-title-text {
  font-size: 10rem;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
  margin-bottom: 64px !important;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  text-align: left;
}

@media screen and (max-width: 750px) {
  .content {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-inner {
    padding: 0 calc(16 / 375 * 100vw);
  }

  .page-title-text {
    font-size: 5rem;
    margin-bottom: 30px !important;
  }
}
</style>
